<template>
  <div class="dict-dialog">
    <el-dialog :fullscreen="dialogFull"  :visible.sync='dialogs' width="800px" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              {{parentParam.parant_name?`添加[${parentParam.parant_name}]`:`添加字典管理`}}
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
      label-width="80px"
      :model="form"
      label-position="top"
      class="add-form"
      :rules="rules"
      ref="dialog">

        <el-row  v-if="!isChild" :gutter="15">
          <el-col :span="12">
            <el-form-item label="字典名称" prop='name'>
              <el-input class="form-input"  v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="字典类型" prop='type'>
              <el-input class="form-input" v-model="form.type"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="isChild" :gutter="15">
          <el-col :span="12">
            <el-form-item label="字典类型">
              <el-input disabled class="form-input" v-model="form.type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据标签" prop='name'>
              <el-input class="form-input" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

         <el-row v-if="isChild" :gutter="15">
          <el-col :span="12">
            <el-form-item label="数据键值" prop='value'>
              <el-input class="form-input" v-model="form.value"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop='sort'>
              <el-input class="form-input" type='number' v-model="form.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 暂时不用状态功能 后端容易出问题 先写死 -->
        <!-- <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="状态">
              <el-select v-model="form.status" placeholder="字典状态" class="form-input">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-form-item label="备注">
          <el-input  class="form-input" type="textarea" v-model="form.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addDict } from '@/api/saasManage'
export default {
  name:'AddDialog',
  components: {},
  props: {
    dialog:{
      type:Boolean,
      default:false,
    },
    parentParam:{
      type:Object,
      default:()=>{}
    },
    isChild:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      dialogs:false,
      dialogFull:false,
      form:{
        parent_id:0,
        name:'',//菜单名称
        remarks:'',// 备注
        status:'1',//状态（0：停用 1:正常 ）
        sort:'', // 排序
        type:'',//字典类型
        value:'' //字典键值
      },
      options:[{
        value: '0',
        label: '停用'
      }, {
        value: '1',
        label: '正常'
      }],
      rules:{
        type:[
           { required: true, message: '字典类型不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '字典名称名称不能为空', trigger: 'blur' },
        ],
        value:[
           { required: true, message: '数据键值不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
    parentParam(newVal){
      this.$nextTick(()=>{
        this.form.parent_id = newVal.parent_id;
        this.form.type = newVal.type;
      })
    }
  },
  methods: {
    /**
     * @desc:保存按钮
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          addDict(this.form).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave')
            this.form = {
              name:'',//菜单名称
              remarks:'',// 备注
              status:1,//状态（0：停用 1:正常 ）
              sort:'', // 排序
              type:'',//字典类型
              value:'', //字典键值
              parent_id:0,
            };
          }).catch(() => {
            this.$message.error(`操作错误`)
          });
        } else {
          return false;
        }
      });
    },
     /**
     * @desc:关闭按钮
     */
    onClose() {
      this.dialogs = false;
      this.form = {
          parent_name:'',//父级名称
          path:'',//组件路径
          name:'',//菜单名称
          is_external:0, //是否外部链接
          remarks:'',// 备注
          type:'目录',
          status:1,//状态（0：停用 1:正常 ）
          route:'', // 路由地址
          permission_code:'', //权限标识
          is_show:1, //是否显示（1：显示，0：隐藏）
          sort:'', // 排序
          code:'', // 编码
          icon:'',//图标
          parent_id:''//父级id
        };
      this.$emit('dialogClose');
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
