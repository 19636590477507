<template>
  <div class="dict-editMain">
    <el-drawer
      :title="isChild?`修改[${form.name}]`:`修改[${form.name}]子表数据`"
      :visible.sync="drawers"
      :before-close="onClose"
      >
      <div class="form-class">
        <el-form ref='form' :model ='form'  label-position="top" :rules="rules">
          <el-form-item label="字典名称" prop='name' v-if='!isChild'>
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="字典类型" prop='type'>
            <el-input v-model="form.type" :disabled="isChild"></el-input>
          </el-form-item>
          <el-form-item label="数据标签" prop='value' v-if='isChild'>
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="数据键值" prop='value' v-if='isChild'>
            <el-input v-model="form.value"></el-input>
          </el-form-item>
          <el-form-item label="显示排序" prop='sort' v-if='isChild'>
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
          <!-- 暂时不用状态功能 后端容易出问题 先写死 -->
          <!-- <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="字典状态" class="form-select">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.remarks"></el-input>
          </el-form-item>
          <el-form-item class="bottom-btn">
            <el-button size="mini" @click="onClose">取消</el-button>
            <el-button size="mini" type="primary" @click='onSave'>保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { editDict } from '@/api/saasManage'
import { deepClone } from '@topology/core';
export default {
  name:'EditDrawer',
  components: {},
  props: {
    drawer:{
      type:Boolean,
      default:false,
    },
    editData:{
      type:Object,
      default:() => {}
    }
  },
  data() {
    return {
      isChild:true, // 是否为子属性修改
      drawers:false,
      options:[{
        value: '0',
        label: '停用'
      }, {
        value: '1',
        label: '正常'
      }],
      form:{},
      rules:{
        code:[
           { required: true, message: '字典编码不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        value:[
           { required: true, message: '数据键值不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    editData(newVal){
      if(newVal && newVal.id){
        if(newVal.children &&newVal.children.length){
          this.isChild = false;
        }else{
          this.isChild = true;
        }
        this.form = deepClone(newVal);
      }
    }
  },
  methods: {
    /**
     * @desc：保存按钮
     */
    onSave(){
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false;
        }
        editDict(this.form).then(res=>{
          if(res.data && res.data.code !== 200){
            return false;
          }
          this.$emit('drawerSave');
        }).catch(() => {
          this.$message.error(`操作错误`);
        });
      })
    },
    /**
     * @desc:关闭按钮
     */
    onClose() {
      this.drawers = false;
      this.$emit('drawerClose');
    },
  },
};
</script>

<style lang="less">
.dict-editMain{
  color: #444;
  text-align:left;
  .form-select{
    width: 100%;
  }
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
  }
}

</style>