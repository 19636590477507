<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-25 14:52:04
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:31:26
 * @FilePath: \dataview-next\src\manage-views\views\org\dept\components\Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="header">
    <div class="search">
      <div class='search-input' >
        <el-input v-model="name"  :placeholder="'搜索' + title +'名称'" @input="onSearch">
          <i slot="suffix" class=" el-input__icon iconfont iconsousuo2"></i>
         </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Header',
  data() {
    return {
      name: '',
    };
  },
  props:{
    title:{
      type:String,
      default:'',
    },
  },
  methods: {
    onSearch(){
      this.$emit('onSearch',this.name);
    },
  },
};
</script>

<style lang="less" scoped>
//  改变input框里面的字体大小和颜色
:deep(.el-input){
  .el-input__inner{
    padding-left: 12px;
    border-radius: 6px;
  }
  .el-input__suffix{
    right: 12;
  }
  input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
    color: #A5ACB3;
  font-size:14px;
  }
  input:-moz-placeholder,textarea:-moz-placeholder{
    color: #A5ACB3;
    font-size:14px;
  }
  input::-moz-placeholder,textarea::-moz-placeholder{
    color: #A5ACB3;
    font-size:14px;
  }
  input:-ms-input-placeholder,textarea:-ms-input-placeholder{
    color: #A5ACB3;
    font-size:14px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 8px;
  height: 64px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 16px;
    .search{
      width: 320px;
      :deep(.el-input__inner){
        padding-right: 0;
      }
      .search-input{
        width: 100%;
        display:flex;
        padding-right: 24px;
        box-sizing: border-box;
        align-items: center;
        span{
          width: 23%;
        }
        div{
          flex:1;
          box-sizing: border-box;
        }
      }
    }
}
</style>
