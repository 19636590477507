<template>
  <div class="dict-main">
    <div class="main-title">
      <div class="main-label">字典管理</div>
      <div>
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="onAdd"
          >新增</el-button
        >
        <el-popconfirm
          v-if="parentSelectId && parentSelectId.length && judgingAPermission(['dict.delete'])"
          class="delete-btn"
          title="确定要删除这个字典吗？"
          @confirm="onSelectDelete('parent')"
        >
          <el-button size="mini" type="danger" icon="el-icon-delete-solid">删除</el-button>
        </el-popconfirm>
        <!-- 暂时不要 -->
        <!-- <el-button size='mini' icon="el-icon-download" >导出</el-button> -->
        <!-- <el-button size='mini' type="primary" icon="el-icon-refresh" @click="getData()">刷新缓存</el-button> -->
      </div>
    </div>
    <el-table
      class="dict-table"
      v-loading="loading"
      :data="dictData"
      :header-cell-style="{ background: '#fafafa' }"
      @selection-change="onSelectChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="inner-main">
            <div class="main-title">
              <div class="main-label">{{ props.row.name }}</div>
              <div>
                <el-button
                  v-if="judgingAPermission(['dict.store'])"
                  size="mini"
                  type="primary"
                  icon="el-icon-plus"
                  @click="onAdd(props.row)"
                  >新增</el-button
                >
                <el-button
                  v-if="
                    childSelectId &&
                    childSelectId.length &&
                    judgingAPermission(['dict.delete'])
                  "
                  size="mini"
                  type="danger"
                  icon="el-icon-delete-solid"
                  @click="onSelectDelete('child')"
                  >删除</el-button
                >
              </div>
            </div>
            <el-table
              :data="props.row.children"
              class="inner-table"
              :header-cell-style="{ background: '#fafafa' }"
              :row-class-name="tableRowClassName"
              @selection-change="onSelectChangeChild"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="字典标签" prop="name"></el-table-column>
              <el-table-column label="字典键值" prop="value"></el-table-column>
              <el-table-column label="字典排序" prop="sort"></el-table-column>
              <el-table-column label="状态">
                <template>
                  <!-- 暂时不用状态 后端容易出问题 先写死 -->
                  <!-- <div>
                    <span class="table-state" :style="{backgroundColor: row.status?'#1890ff':'#f11111',color: row.status?'#1890ff':'#f11111'}">
                    </span>
                    <span>{{row.status?'正常':'停用'}}
                    </span>
                  </div> -->
                  <div>
                    <span class="table-state"> </span>
                    <span>正常 </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="备注" prop="remarks"> </el-table-column>
              <el-table-column
                label="创建时间"
                prop="created_at"
                align="center"
                width="300px"
              ></el-table-column>
              <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" @click="onEdit(scope.row)"
                    >修改</el-button
                  >
                  <el-popconfirm
                    class="delete-btn"
                    title="确定要删除这个字典吗？"
                    @confirm="onDelete(scope.row)"
                  >
                    <el-button size="mini" type="text" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="字典名称" prop="name"></el-table-column>
      <el-table-column
        label="字典类型"
        prop="type"
        align="center"
      ></el-table-column>
      <el-table-column label="状态">
        <template>
          <!-- 暂时不用状态 后端容易出问题 先写死 -->
          <!-- <div>
            <span class="table-state" :style="{backgroundColor: row.status?'#1890ff':'#f11111',color: row.status?'#1890ff':'#f11111'}">
            </span>
            <span>{{row.status?'正常':'停用'}}
            </span>
          </div> -->
          <div>
            <span class="table-state"> </span>
            <span>正常 </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remarks"> </el-table-column>
      <el-table-column
        label="创建时间"
        prop="created_at"
        align="center"
        width="300px"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="200px">
        <template #default="{ row }">
          <el-button
            size="mini"
            type="text"
            @click="onEdit(row)"
            v-if="judgingAPermission(['dict.update'])"
            >修改</el-button
          >
          <el-popconfirm
            v-if="judgingAPermission(['dict.delete'])"
            class="delete-btn"
            title="确定要删除这个字典吗？"
            @confirm="onDelete(row)"
          >
            <el-button size="mini" type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 编辑抽屉 -->
    <EditDrawer
      :drawer="drawer"
      :editData="editData"
      @drawerClose="drawerClose"
      @drawerSave="drawerSave"
    >
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog
      :isChild="isChild"
      :dialog="dialog"
      :parentParam="parentData"
      @dialogClose="dialogClose"
      @dialogSave="dialogSave"
    >
    </AddDialog>
  </div>
</template>

<script>
import { getDict, detDict } from '@/api/saasManage'
import { Popconfirm } from 'element-ui'
import EditDrawer from './EditDrawer.vue'
import AddDialog from './AddDialog.vue'
import { judgingAPermission } from '@/utils/tools'
export default {
  components: { EditDrawer, AddDialog, 'el-popconfirm': Popconfirm },
  data() {
    return {
      judgingAPermission: judgingAPermission,
      parentSelectId: [],
      childSelectId: [],
      isChild: false,
      loading: false,
      dictData: [],
      drawer: false,
      dialog: false,
      editData: {},
      parentData: {},
      pager: {
        page: 1,
        size: 15,
        total: 0,
      },
      search: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    tableRowClassName() {
      return 'height-light'
    },
    /**
     * @desc:关闭新增弹窗
     */
    dialogClose() {
      this.dialog = !this.dialog
      this.isChild = false
    },
    dialogSave() {
      this.dialog = !this.dialog
      this.getData()
    },
    drawerSave() {
      this.drawer = !this.drawer
      this.getData()
    },
    // /**
    //  * @desc:关闭编辑抽屉
    //  */
    drawerClose() {
      this.drawer = !this.drawer
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row) {
      this.editData = row
      this.drawer = !this.drawer
    },
    onAdd(row) {
      this.isChild = false
      if (row && row.id) {
        this.parentData = {
          type: row.type,
          parant_name: row.name,
          parent_id: row.id,
        }
        this.isChild = true
      }
      this.dialog = !this.dialog
    },
    /**
     * @desc:普通删除
     */
    onDelete(row) {
      detDict({ id: [row.id] })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.getData()
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    /**
     * @desc: 批量删除
     */
    onSelectDelete(type) {
      let detID = []
      if (type === 'parent') {
        detID = this.parentSelectId
      } else {
        detID = this.childSelectId
      }
      detDict({ id: detID })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.getData()
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    /**
     * @desc:筛选
     */
    onSearch(data) {
      this.search = data
      this.getData()
    },
    onRefresh() {
      this.search = ''
      this.getData()
    },
    /**
     * @desc: 分页数量
     * @param {Number} size
     */
    onSize(size) {
      this.pager.page = 1
      this.pager.size = size
      this.getData()
    },
    /**
     * @desc: 切换页码
     * @param {Number} page
     */
    onPage(page) {
      this.pager.page = page
      this.getData()
    },
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val) {
      this.parentSelectId = []
      val.forEach((data) => {
        this.parentSelectId.push(data.id)
      })
    },
    /**
     * @desc:子表格选择时
     */
    onSelectChangeChild(val) {
      this.childSelectId = []
      val.forEach((data) => {
        this.childSelectId.push(data.id)
      })
    },
    /**
     * @desc: 得到字典管理列表
     */
    getData() {
      this.loading = true
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.search,
      }
      getDict(params)
        .then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.dictData = res.data.data.data
            this.pager = {
              page: res.data.data.current_page,
              size: res.data.data.per_page,
              total: res.data.data.total,
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
  },
}
</script>
<style lang="less">
@import '../../../../css/manageMain.less';
.dict-main {
  background-color: #fff;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  .dict-table {
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
    .inner-main {
      padding: 10px 50px;
      box-sizing: border-box;
      background-color: #fafafa;
    }
    .inner-table {
      background-color: #fafafa;
    }
  }
}
</style>
